import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from '../../shared/lib/api';
import { InputElementEnum } from '../../../../shared/lib/types';
import { getQueryParam, replaceSpecialChars, setQueryParam } from '../../../../shared/lib/utils';
import SelectInput, { ISelectInputOption } from '../../../form/SelectInput';
import TextInput from '../../../form/TextInput';
import { filteredVeilederliste } from '../veilederHelper';
import { CollapseWithChild as CollapseComponent, CollapseWithChild } from '../../../../prototype/components/Collapse';
import { IVeilederliste, IVeilederListeItem, IVeilederUnderKategorier, VeilederType, VeilederTypeNames } from '../../shared/lib/types';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';

type TParams = {
    kundeId: string;
};

const getVeilederUrl = (veilederId: string, kundeId: string | undefined, tittel: string | undefined) => {
    if (kundeId) {
        return `/kunder/${kundeId}/veileder/${veilederId}/${replaceSpecialChars(tittel, '-')}`;
    }

    return `/veileder/${veilederId}/${replaceSpecialChars(tittel, '-')}`;
};
const getMalUrl = (veilederId: string, kundeId: string | undefined, tittel: string | undefined) => {
    if (kundeId) {
        return `/mal/${veilederId}/kunde/${kundeId}/${replaceSpecialChars(tittel, '-')}`;
    }

    return `/mal/${veilederId}/${replaceSpecialChars(tittel, '-')}`;
};
const Veilederoversikt = () => {
    const queryParamKey = 'q';
    const typeParamKey = 't';
    const { kundeId } = useParams<TParams>();
    const [veilederliste, setVeilederliste] = useState<IVeilederliste>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [egetforetak, setEgetforetak] = useState<boolean>(false);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [searchQuery, _setSearchQuery] = useState<string>(getQueryParam(queryParamKey));
    const setSearchQuery = (searchQuery: string) => {
        const q = searchQuery.trimStart();
        setQueryParam(queryParamKey, q);
        _setSearchQuery(q);
    };
    const [veilederTypeOptions] = useState<Array<ISelectInputOption>>(
        VeilederTypeNames.map((name, i) => {
            return {
                text: name,
                value: i,
            };
        }).reverse()
    );

    const [selectedVeilederType, _setSelectedVeilederType] = useState<string>(getQueryParam(typeParamKey) ?? '');
    const setSelectedVeilederType = (type: VeilederType) => {
        setQueryParam(typeParamKey, type.toString());
        _setSelectedVeilederType(type.toString());
    };

    useEffect(() => {
        (() => {
            Promise.all([
                api.getVeiledere().then((res) => setVeilederliste(res)),
                api.getInnloggetBruker().then((res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetforetak(true);
                    } else {
                        if (kundeId) {
                            api.getKunde(kundeId).then((kundeRes) => setKundeNavn(kundeRes.navn));
                        }
                    }
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Veileder oversikt';
        })();
    }, [kundeId]);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/veiledere" />;
    }

    return (
        <div className="veiledere">
            <div className={`page-header${kundeNavn ? ' page-header--dark' : ''}`}>
                <div className="container">
                    <h1>{kundeNavn ? 'Start veileder for kunde' : 'Veilederoversikt'}</h1>
                </div>
            </div>
            {kundeNavn && (
                <div className="page-header">
                    <div className="container">
                        <div className="col-md-6 d-flex align-items-center">
                            <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                            <h2>{kundeNavn}</h2>
                        </div>
                    </div>
                </div>
            )}
            <div className="page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <TextInput
                                label="SØK"
                                name="search-input"
                                value={searchQuery}
                                type={InputElementEnum.search}
                                placeholder="Veiledernavn"
                                focus=""
                                onInputChange={(name, value) => {
                                    setSearchQuery(value);
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <SelectInput
                                label="TYPE"
                                firstOptionText="Alle"
                                createFirstOption={true}
                                name="veileder-type-select"
                                value={selectedVeilederType}
                                options={veilederTypeOptions}
                                focus=""
                                type={InputElementEnum.select}
                                onInputChange={(name, value) => {
                                    setSelectedVeilederType(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="veiledere__list">
                        {veilederliste &&
                            filteredVeilederliste(veilederliste, selectedVeilederType, searchQuery).hovedKategorier.map((kategori, index) => {
                                const hasVeiledere = kategori.underKategorier.some((kat) => kat.veiledere.length > 0);
                                return (
                                    hasVeiledere && (
                                        <CollapseComponent key={index} name={kategori.navn} show={searchQuery || selectedVeilederType ? true : false}>
                                            <div>
                                                {kategori.underKategorier.map((underKategori, index) => (
                                                    <UnderKategorierComponent
                                                        key={index}
                                                        underKategori={underKategori}
                                                        kundeId={kundeId}
                                                        show={searchQuery || selectedVeilederType ? true : false}
                                                    />
                                                ))}
                                            </div>
                                        </CollapseComponent>
                                    )
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface UnderKategorierComponentProps {
    readonly underKategori: IVeilederUnderKategorier;
    readonly kundeId?: string;
    readonly show: boolean;
}

const UnderKategorierComponent = ({ underKategori, kundeId, show }: UnderKategorierComponentProps) => {
    const hasVeiledere = underKategori.veiledere.length > 0;
    return (
        hasVeiledere && (
            <CollapseWithChild name={underKategori.navn} containerCss="veilder-collapse-container" toggleCss="veilder-collapse-toggle" show={show}>
                {underKategori.veiledere.map((veileder, index) => (
                    <VeilederComponent key={index} veileder={veileder} kundeId={kundeId} />
                ))}
            </CollapseWithChild>
        )
    );
};

interface VeilederComponentProps {
    readonly veileder: IVeilederListeItem;
    readonly kundeId?: string;
}
function VeilederComponent({ veileder, kundeId }: VeilederComponentProps) {
    let navigate = useNavigate();
    const isVeilederTypeMaler = VeilederTypeNames[veileder.type] === 'Maler';
    const veilederUrl = getVeilederUrl(veileder.id, kundeId, veileder.name);
    const url = isVeilederTypeMaler ? `/mal/${veileder.id}/${replaceSpecialChars(veileder.name, '-')}` : veilederUrl;

    return (
        <div className="row veileder__list__body">
            <div
                className="col-md-6 veileder__list__title"
                dangerouslySetInnerHTML={{
                    __html: `${veileder.name}`,
                }}
                onClick={() => navigate(url)}
            />
            <div className="col-md-6">
                {isVeilederTypeMaler ? (
                    <Link to={getMalUrl(veileder.id, kundeId, veileder.name)} className="btn btn--secondary btn--sm">
                        Last ned Mal
                    </Link>
                ) : (
                    <Link to={url} className="btn btn--secondary btn--sm">
                        Start veileder
                    </Link>
                )}
            </div>
        </div>
    );
}
export default Veilederoversikt;
